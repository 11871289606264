<template>
  <div>
    <teacher-reviews :teacherReviewSection="1" />
  </div>
</template>

<script>
import TeacherReviews from "@/components/TeacherReviews";

export default {
  name: "TeacherReviewList",
  components: {
    TeacherReviews
  }
};
</script>

<style scoped></style>
